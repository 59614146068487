import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DefaultVarianceRequest, VarianceRequest } from "./VarianceRequest";
import { ReduxUpdate } from "../../../../utils/ReduxUpdateCommands";
import { varianceApi } from "../../variance-api";
import { Individual } from "../individual/Individual";
import { IncidentTypeDetail } from "./IncidentTypeDetail";
import { VarianceDocument } from "./VarianceDocuments";
import { IncidentSubTypeDetail } from "./IncidentSubTypeDetail";

interface VarianceRequestState {
  varianceRequest: VarianceRequest;
}

const initialState: VarianceRequestState = {
  varianceRequest: DefaultVarianceRequest(),
};
export const VarianceRequestSlice = createSlice({
  name: "varianceRequest",
  initialState,
  reducers: {
    editVariance: (state, action: PayloadAction<ReduxUpdate>) => {
      //@ts-ignore
      state.varianceRequest[action.payload.name] = action.payload.value;
    },
    editVarianceIndividual: (state, action: PayloadAction<ReduxUpdate>) => {
      //@ts-ignore
      state.varianceRequest.individual[action.payload.name] =
        action.payload.value;
    },
    setVarianceIndividual: (state, action: PayloadAction<Individual>) => {
      //@ts-ignore
      state.varianceRequest.individual = action.payload;
    },
    editVarianceLocation: (state, action: PayloadAction<ReduxUpdate>) => {
      //@ts-ignore
      state.varianceRequest.location[action.payload.name] =
      action.payload.value;
    },
    editVarianceLocationDetails: (state, action: PayloadAction<ReduxUpdate>) => {
      //@ts-ignore
      state.varianceRequest.locationDetails[action.payload.name] =
      action.payload.value;
    },
    editIncidentTypeDetails: (state, action: PayloadAction<IncidentTypeDetail>) => {
      //@ts-ignore
      state.varianceRequest.incidentTypeDetails.push(action.payload);
    },
    editMedicationEventMedDetails: (state, action: PayloadAction<ReduxUpdate>) => {
      //@ts-ignore
      state.varianceRequest.medicationDetail[action.payload.name] =
      action.payload.value;
    },
    editDirectorResponseActions: (state, action: PayloadAction<ReduxUpdate>) => {
      //@ts-ignore
      state.varianceRequest.directorResponseAction[action.payload.name] =
      action.payload.value;
    },
    editDirectorResponseFallActions: (state, action: PayloadAction<ReduxUpdate>) => {
      //@ts-ignore
      state.varianceRequest.directorResponseAction.fallAction[action.payload.name] =
      action.payload.value;
    },
    editFollowUp: (state, action: PayloadAction<ReduxUpdate>) => {
      //@ts-ignore
      state.varianceRequest.followUp[action.payload.name] = 
      action.payload.value;
    },
    addVarianceDocument: (state, action: PayloadAction<VarianceDocument>) => {
      state.varianceRequest.documents.push(action.payload);
    },
    addAssignedUser: (state, action: PayloadAction<ReduxUpdate>) => {
      //@ts-ignore
      state.varianceRequest.assignedUsers.push(action.payload.value);
    },
    editSubTypeDetail: (state, action: PayloadAction<IncidentSubTypeDetail>) => {
      //@ts-ignore
      state.varianceRequest.incidentSubTypeDetails.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        varianceApi.endpoints.findVarianceRequest.matchFulfilled,
        (state, { payload }) => {
          state.varianceRequest = payload;
        }
      ).addMatcher(
        varianceApi.endpoints.incidentCommentAdd.matchFulfilled,
        (state,{payload}) => {state.varianceRequest.comments.push(payload)}
      ).addMatcher(
        varianceApi.endpoints.varianceStatusUpdate.matchFulfilled,
        (state, { payload }) => {
          state.varianceRequest = payload;
        }
      ).addMatcher(
        varianceApi.endpoints.incidentWitnessAdd.matchFulfilled,
        (state, { payload }) => {
          state.varianceRequest.witnesses?.push(payload);
          }
      ).addMatcher(varianceApi.endpoints.documentRemove.matchFulfilled,
        (state,{payload})=>{state.varianceRequest.documents = state.varianceRequest.documents.filter(x=>x.id !== payload.id)});
  },
});

export const {
  editVariance,
  editVarianceIndividual,
  setVarianceIndividual,
  editVarianceLocation,
  editVarianceLocationDetails,
  editIncidentTypeDetails,
  editMedicationEventMedDetails,
  editDirectorResponseActions,
  editDirectorResponseFallActions,
  editFollowUp,
  addVarianceDocument,
  editSubTypeDetail
} = VarianceRequestSlice.actions;
export default VarianceRequestSlice.reducer;
