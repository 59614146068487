export const SurgicalProcedueImplantOptions = [
{
    description: "Expiration Date not Documented",
    name: "ImplantIssueExpirationDateNotDocumented",
},
{
    description: "Implant Integrity Compromised",
    name: "ImplantIssueImplantIntegrityCompromised",
},
{
    description: "Lot Number Not Documented",
    name: "ImplantIssueLotNumberNotDocumented",
},
{
    description: "Wrong Implant",
    name: "ImplantIssueWrongImplant",
}
];