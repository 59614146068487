import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { Typography } from "@mui/material";
import { removeProcedure } from "../../../../../../services/verifyService/model/verification/VerificationSlice";



function DrgRemovalMessage() {  
  const dispatch = useAppDispatch();
  const verification = useAppSelector((state) => state.verification.verification);
  const [removedDrg, setRemovedDrg] = useState("");

  React.useEffect(() => {
    if (verification.drgCombo.PreviousDrg !== "0" && verification.drgCombo.DRG !== verification.drgCombo.PreviousDrg) {
      setRemovedDrg(verification.drgCombo.PreviousDrg);
      let existingDrg = verification.procedures.find(p => p.code === verification.drgCombo.PreviousDrg);
      if (existingDrg) {
        dispatch(removeProcedure(existingDrg.priority));
      }
    }
  }, [removedDrg, dispatch]);

  return (
    <React.Fragment>
      {removedDrg && (
      <Typography variant="body1" color="red">Replaces existing DRG: {removedDrg}</Typography>
      )}
    </React.Fragment>
  );
}

export { DrgRemovalMessage };
