import React from "react"
import {ContributionMustBePositive} from '../../../specs/ContributionMustBePositive';
import {ContributionMustBeMoreThan20} from '../../../specs/ContributionMustBeMoreThan20';
import {ContributionMustBeMoreThan50} from '../../../specs/ContributionMustBeMoreThan50';
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { Alert, Button, Stack, Typography } from "@mui/material";
import { useMyProfileQuery } from "../../../../../services/profileService/profile-api";
import { useSaveApprovalMutation, verifyApi } from "../../../../../services/verifyService/verify-api";
import { ApprovalUpdateCommand, PayerForCenterRequest } from "../../../../../services/verifyService/commands";
import { useParams } from "react-router";
import { InpatientOnly } from "../../../specs/InpatientOnly";
import { useCaseFindQuery } from "../../../../../services/scheduleService/schedule-api";
import { useCenterFindQuery, usePayerDetailsQuery } from "../../../../../services/orgService/org-api";
import { ExcludeAtAsc } from "../../../specs/ExcludeAtAsc";
import { ApprovalNote } from "./approvals/ApprovalNote";
import { ApprovalNoteView } from "./approvals/ApprovalNoteView";
import { ApprovalComparison } from "./approvals/ApprovalComparison";
import { ACDFAlert } from "../../../specs/ACDFAlert";

function EstimateAlerts(){
    const {id} = useParams();
    const dispatch = useAppDispatch();
    const verification = useAppSelector((state) => state.verification.verification);
    const schedule = useCaseFindQuery(id!);
    const primaryPayer = schedule.data?.insurances.find(p => p.priority === 1)
    const calctype = usePayerDetailsQuery(new PayerForCenterRequest(verification.center, primaryPayer?.payerCode??'')).data?.financialClass;
    const center = useCenterFindQuery(id!.split("-")[0]);
    const profile = useMyProfileQuery();
    const [approve] = useSaveApprovalMutation();
    const canApprove=()=>{
        if(profile.data?.claimsAccess.find(p => p.code === 'VobApprover') != undefined){
            return true; }
            return false;
    }

    
    return <React.Fragment>
        {calctype !== 'Medicare' && !new ContributionMustBePositive().IsSatisfiedBy(verification) &&
            <Alert color={verification.approval.isApproved ? 'success': 'error'}>
                {verification.approval.isApproved &&
                <Stack direction = 'row' gap={5} alignItems='center'>
                    <Typography variant='body2'>{'Low contribution approved by: ' + verification.approval.approvedBy}</Typography>
                    <ApprovalNote/>
                    <ApprovalNoteView/>
                    <ApprovalComparison/>
                </Stack> 
                }
                {verification.approval.isApproved === false &&
                <Stack direction='row' gap={5} alignItems='center'>
                    <Typography variant='body2'>Contribution margin below 50% requires administrative approval.</Typography>
                    <ApprovalNote/>
                    <ApprovalComparison/>
                </Stack> 
                }
            </Alert>
        }
        {calctype === 'Medicare' && !new ContributionMustBeMoreThan20().IsSatisfiedBy(verification) &&
            <Alert color={verification.approval.isApproved ? 'success': 'error'}>
                {verification.approval.isApproved &&
                <Stack direction = 'row' gap={5} alignItems='center'>
                    <Typography variant='body2'>{'Low contribution approved by: ' + verification.approval.approvedBy}</Typography>
                    <ApprovalNote/>
                    <ApprovalNoteView/>
                    <ApprovalComparison/>
                </Stack> 
                }
                {verification.approval.isApproved === false &&
                <Stack direction='row' gap={5} alignItems='center'>
                    <Typography variant='body2'>Contribution margin below 20% requires administrative approval.</Typography>
                    <ApprovalNote/>
                    <ApprovalComparison/>
                </Stack> 
                }
            </Alert>
        }
         {(calctype === "Aetna" ||
          calctype === "Aetna Medicare" ||
          calctype === "Aetna Medicaid" ||
          calctype === "Cigna" ||
          calctype === "Cigna Medicare" ||
          calctype === "Cigna Connect" ||
          calctype === "Cigna Healthspring" ||
          calctype === "UHC" ||
          calctype === "UHC Medicaid" ||
          calctype === "UHC Medicare" ||
          calctype === "Medicare") &&
          schedule.data?.admissionType == "OP" &&
          new InpatientOnly().IsSatisfiedBy(verification) && (
            <Alert color="error">
              Inpatient only procedure codes on an outpatient case.
            </Alert>
          )}
        {calctype==='Medicare' && center.data?.centerType =='ASC' && new ExcludeAtAsc().IsSatisfiedBy(verification) &&
            <Alert color="error">Procedure code not allowed at ASC.</Alert>
        }
        {center.data?.code==='MBM' && new ACDFAlert().IsSatisfiedBy(verification) &&
            <Alert color="error">If 22551 and more than 1 unit of 22552 are entered then 22846 must be included</Alert>
        }


    </React.Fragment>
}

export {EstimateAlerts}