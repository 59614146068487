export const cptCombo = [
    { DRG: "24", CPT: ["61860"] },
    { DRG: "27", CPT: ["62120"] },
    { DRG: "27", CPT: ["61512"] },
    { DRG: "29", CPT: ["63685"] },
    { DRG: "29", CPT: ["63276"] },
    { DRG: "33", CPT: ["62223"] },
    { DRG: "33", CPT: ["62160"] },
    { DRG: "42", CPT: ["61885"] },
    { DRG: "142", CPT: ["69930"] },
    { DRG: "145", CPT: ["62140"] },
    { DRG: "331", CPT: ["44140"] },
    { DRG: "331", CPT: ["44144"] },
    { DRG: "352", CPT: ["49525"] },
    { DRG: "352", CPT: ["49540"] },
    { DRG: "352", CPT: ["49550"] },
    { DRG: "352", CPT: ["49505"] },
    { DRG: "352", CPT: ["49507"] },
    { DRG: "352", CPT: ["49520"] },
    { DRG: "399", CPT: ["44970"] },
    { DRG: "399", CPT: ["44950"] },
    { DRG: "402", CPT: ["22558", "22612"] },
    { DRG: "402", CPT: ["22558", "22633"] },
    { DRG: "402", CPT: ["22558", "22630"] },
    { DRG: "415", CPT: ["47600"] },
    { DRG: "415", CPT: ["47605"] },
    { DRG: "419", CPT: ["47562"] },
    { DRG: "419", CPT: ["47563"] },
    { DRG: "419", CPT: ["47564"] },
    { DRG: "428", CPT: ["22558", "22612", "22585", "22614"] },
    { DRG: "428", CPT: ["22558", "22633", "22585", "22634"] },
    { DRG: "428", CPT: ["22558", "22630", "22585", "22632"] },
    { DRG: "430", CPT: ["22551", "22600"] },
    { DRG: "430", CPT: ["22554", "22600"] },
    { DRG: "448", CPT: ["22633", "22634"] },
    { DRG: "448", CPT: ["22612", "22614"] },
    { DRG: "448", CPT: ["22558", "22585"] },
    { DRG: "448", CPT: ["22610", "22614"] },
    { DRG: "448", CPT: ["22630", "22632"] },
    { DRG: "448", CPT: ["22556", "22585"] },
    { DRG: "451", CPT: ["22633"] },
    { DRG: "451", CPT: ["22612"] },
    { DRG: "451", CPT: ["22558"] },
    { DRG: "451", CPT: ["22610"] },
    { DRG: "451", CPT: ["22630"] },
    { DRG: "451", CPT: ["22556"] },
    { DRG: "465", CPT: ["11010"] },
    { DRG: "468", CPT: ["27487"] },
    { DRG: "470", CPT: ["27447"] },
    { DRG: "470", CPT: ["27130"] },
    { DRG: "473", CPT: ["22551"] },
    { DRG: "473", CPT: ["22554"] },
    { DRG: "482", CPT: ["27146"] },
    { DRG: "482", CPT: ["27232"] },
    { DRG: "482", CPT: ["27236"] },
    { DRG: "482", CPT: ["27269"] },
    { DRG: "483", CPT: ["23472"] },
    { DRG: "487", CPT: ["27310"] },
    { DRG: "489", CPT: ["27486"] },
    { DRG: "494", CPT: ["24586"] },
    { DRG: "494", CPT: ["24515"] },
    { DRG: "494", CPT: ["24546"] },
    { DRG: "494", CPT: ["24545"] },
    { DRG: "494", CPT: ["24579"] },
    { DRG: "494", CPT: ["24587"] },
    { DRG: "494", CPT: ["27870"] },
    { DRG: "494", CPT: ["28725"] },
    { DRG: "497", CPT: ["22850"] },
    { DRG: "497", CPT: ["22852"] },
    { DRG: "498", CPT: ["27248"] },
    { DRG: "502", CPT: ["27381"] },
    { DRG: "505", CPT: ["28292"] },
    { DRG: "505", CPT: ["28293"] },
    { DRG: "505", CPT: ["28294"] },
    { DRG: "505", CPT: ["28296"] },
    { DRG: "505", CPT: ["28297"] },
    { DRG: "505", CPT: ["28298"] },
    { DRG: "505", CPT: ["28299"] },
    { DRG: "505", CPT: ["28153"] },
    { DRG: "505", CPT: ["28750"] },
    { DRG: "505", CPT: ["28445"] },
    { DRG: "505", CPT: ["28002"] },
    { DRG: "517", CPT: ["22849"] },
    { DRG: "517", CPT: ["23474"] },
    { DRG: "517", CPT: ["23473"] },
    { DRG: "517", CPT: ["22513"] },
    { DRG: "517", CPT: ["22514"] },
    { DRG: "518", CPT: ["22856"] },
    { DRG: "518", CPT: ["22857"] },
    { DRG: "520", CPT: ["63081"] },
    { DRG: "520", CPT: ["63047"] },
    { DRG: "520", CPT: ["63046"] },
    { DRG: "520", CPT: ["63282"] },
    { DRG: "585", CPT: ["S2068"] },
    { DRG: "585", CPT: ["19364"] },
    { DRG: "617", CPT: ["28800"] },
    { DRG: "626", CPT: ["60240"] },
    { DRG: "626", CPT: ["60271"] },
    { DRG: "626", CPT: ["60252"] },
    { DRG: "626", CPT: ["60254"] },
    { DRG: "626", CPT: ["60260"] },
    { DRG: "626", CPT: ["60270"] },
    { DRG: "857", CPT: ["63688"] },
    { DRG: "857", CPT: ["49082"] },
    { DRG: "857", CPT: ["61618"] },
    { DRG: "857", CPT: ["62100"] },
    { DRG: "857", CPT: ["22010"] },
    { DRG: "863", CPT: ["10180"] },
    { DRG: "909", CPT: ["20692"] },
    { DRG: "920", CPT: ["10140"] },
];
