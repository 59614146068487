import { GridCellProps } from "@progress/kendo-react-grid"
import React from "react"
import { RefundStartCommand } from "../../../../services/refundService/commands"
import { useStartRefundMutation } from "../../../../services/refundService/refund-api";
import { GridButton } from "../../../../components";
import { toggleRefundInitiateDialog } from "../../../../app/slices/RefundModuleSlice";
import { useAppDispatch } from "../../../../app/hooks";
import { useNavigate } from "react-router";

function RefundInitiateCommand(props:GridCellProps){
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const command = new RefundStartCommand(props.dataItem.id,
        props.dataItem.center,props.dataItem.accountNumber,
        props.dataItem.lastName,props.dataItem.firstName,
        props.dataItem.visitKey,props.dataItem.serviceDate);

    const [initiate] = useStartRefundMutation();

    const startRefund=()=>{
        initiate(command).unwrap()
        .then((fullfilled)=>{dispatch(toggleRefundInitiateDialog());
             navigate('/refundRequestDetail/'+ fullfilled.caseId + '/' + fullfilled.id);});
    }
    
    return <React.Fragment>
        <GridButton 
            onClick={()=>{startRefund();}}>
            Initiate</GridButton>
    </React.Fragment>
}

export {RefundInitiateCommand}