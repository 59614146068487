import { Stack, TextField, Typography } from "@mui/material";
import { Switch } from "@progress/kendo-react-inputs"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { editBenefitsStatus, editBenefitsStatusReasons, editComboOfBenefitsIssue, editVerify } from "../../../../../services/verifyService/model/verification/VerificationSlice";
import { CanCompleteBenefits } from "./specs/CanCompleteBenefits";

function BenefitsStatus(){
    const dispatch = useAppDispatch();
    const verification = useAppSelector((state) => state.verification.verification);
    const canComplete=()=> new CanCompleteBenefits().IsSatisfiedBy(verification);
    return <React.Fragment>
        <Typography sx={{borderBottom:'2px solid gray'}} variant='h6' className="psn-green">Benefits Status</Typography>
   
        <Stack gap={1} margin={1}>
            <div>
                <label>Benefits Complete</label><br/>
                <Switch name='complete' value={verification.benefitsStatus.complete} checked={verification.benefitsStatus.complete} onChange={(e) => {dispatch(editBenefitsStatus(e))}} disabled={!canComplete()}/>
            </div>
            <div>
                <Stack gap={1}>
                    <div>
                        <label>Invalid Patient Info</label><br/>
                        <Switch name='invalidPatient' value={verification.benefitsStatus.invalidPatient} checked={verification.benefitsStatus.invalidPatient} onChange={(e) => dispatch(editBenefitsStatus(e))}/>
                    </div>
                    <div>
                        {verification.benefitsStatus.invalidPatient &&
                        <TextField label='Enter invalid Patient Reason' name='invalidPatientReason' multiline value={verification.benefitsStatus.invalidPatientReason} onChange={(e)=>dispatch(editBenefitsStatusReasons(e))} InputLabelProps={{shrink:true}} fullWidth/>}
                    </div>
                </Stack>
            </div>
            <div>
                <Stack direction='row' gap={2}>
                    <Stack gap={1}>
                        <div>
                            <label>Invalid Insurance</label><br/>
                            <Switch name='invalidInsurance' value={verification.benefitsStatus.invalidInsurance} checked={verification.benefitsStatus.invalidInsurance} onChange={(e) => dispatch(editBenefitsStatus(e))}/>
                        </div>
                    </Stack>
                    <div>
                        <label>Insurance Termed</label><br/>
                        <Switch name='insuranceTermed' value={verification.benefitsStatus.insuranceTermed} checked={verification.benefitsStatus.insuranceTermed} onChange={(e) => dispatch(editBenefitsStatus(e))}/>
                    </div>
                </Stack>
            </div>
            <div>
                    {verification.benefitsStatus.invalidInsurance &&   
                    <TextField label='Enter invalid insurance reason' name='invalidInsuranceReason' multiline value={verification.benefitsStatus.invalidInsuranceReason} onChange={(e)=>dispatch(editBenefitsStatusReasons(e))} InputLabelProps={{shrink:true}} fullWidth/>}
                </div>
            <div>
                <Stack gap={1}>
                    <div>
                        <label>Invalid CPT/DRG</label><br/>
                        <Switch name='invalidCodes' value={verification.benefitsStatus.invalidCodes} checked={verification.benefitsStatus.invalidCodes} onChange={(e) => dispatch(editBenefitsStatus(e))}/>
                    </div>
                    <div>
                        {verification.benefitsStatus.invalidCodes &&
                        <TextField label='Enter invalid code reason' name='invalidCodeReason' multiline value={verification.benefitsStatus.invalidCodeReason} onChange={(e)=>dispatch(editBenefitsStatusReasons(e))} InputLabelProps={{shrink:true}} fullWidth/>}
                    </div>
                </Stack>
            </div>
            <div>
                <label>COB Issue</label><br/>
                <Switch name='isCobIssue' value={verification.comboOfBenefitsIssue.isCobIssue} checked={verification.comboOfBenefitsIssue.isCobIssue} onChange={(e) => dispatch(editComboOfBenefitsIssue(e))}/>
            </div>

        </Stack>
    </React.Fragment>
}

export {BenefitsStatus}