import { Box } from "@mui/material";
import React from "react";
import { FallPostReasonOptions } from "./FallPostReasonOptions";
import { FallPostMentalStatusOptions } from "./FallPostMentalStatusOptions";
import { IncidentSubTypeDetails } from "../../IncidentSubTypeDetails";

function FallPostAssessment(props: {canEdit: boolean}) {
        return (
                <React.Fragment>
                    <Box mt={2} mb={2} sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                        <IncidentSubTypeDetails canEdit={props.canEdit} formName="Post Fall Assessment" options={[{description: "Fall Assessment Updated", name: "FallPostAssessUpdated"}]} />
                        <IncidentSubTypeDetails canEdit={props.canEdit} formName="Reason For Fall" options={FallPostReasonOptions} />
                        <IncidentSubTypeDetails canEdit={props.canEdit} formName="Mental Status" options={FallPostMentalStatusOptions} />
                    </Box>
                </React.Fragment>
        );
};

export { FallPostAssessment };