import { Box, Stack } from "@mui/material";
import React from "react";
import { AdverseDrugTypes } from "./AdverseDrugTypes";
import { IncidentSubTypeDetails } from "../IncidentSubTypeDetails";
import { AdverseDrugDetailsOptions } from "./AdverseDrugDetailsOptions";

function AdverseDrug(props: {canEdit: boolean}) {
  return (
    <React.Fragment>
      <Box mt={2} mb={2} sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
        <Stack
          direction="row"
          spacing={3}
          alignItems="baseline"
        >
            <AdverseDrugTypes canEdit={props.canEdit}/>
            <IncidentSubTypeDetails canEdit={props.canEdit} formName="Adverse Drug Event Details" freeformName="AdverseDrugDetailFreeForm" freeformLabel="Other" options={AdverseDrugDetailsOptions}/>
            
        </Stack>
      </Box>
    </React.Fragment>
  );
}

export { AdverseDrug };