export interface IncidentTypeDetail{
    name:string;
    description:string;
    checked:boolean;
}

export function DefaultIncidentTypeDetail():IncidentTypeDetail{
    return {name:'', description:'', checked:false}
}

export interface SubTypeDetail {
  name: string;
  description: string;
  checked: boolean;
}

export function DefaultSubTypeDetail(): SubTypeDetail {
  return { name: '', description: '', checked: false };
}