import { MenuItem, TextField } from "@mui/material";
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks"
import { editComboOfBenefitsIssueStatus } from "../../../../../services/verifyService/model/verification/VerificationSlice";

function CobIssueStatus(){
    const dispatch = useAppDispatch();
    const verification = useAppSelector((state) => state.verification.verification);

    return <React.Fragment>
        <TextField sx={{width:'220px'}} variant='outlined' label='COB updated in last 12 Months?' name='cobIssueStatus' value={verification.comboOfBenefitsIssue?.cobIssueStatus} size='small'
                onChange={(e) => dispatch(editComboOfBenefitsIssueStatus(e))}
                InputLabelProps={{shrink:true}} select>
                    <MenuItem value='Unknown'>Unknown</MenuItem>
                    <MenuItem value='Yes'>Yes</MenuItem>
                    <MenuItem value='No'>No</MenuItem>
        </TextField>
    </React.Fragment>
}

export {CobIssueStatus}