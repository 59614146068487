import { Verification } from "../../../../../../services/verifyService/model";
import { CompositeSpecification } from "../../../../../../utils/Specification";


export class CanCompleteBenefits extends CompositeSpecification<Verification>{
    IsSatisfiedBy(candidate: Verification): boolean {
        if(candidate.benefitsStatus.invalidPatient)
            return false;
        if(candidate.benefitsStatus.invalidInsurance)
            return false;
        if(candidate.benefitsStatus.invalidCodes)
            return false;
        if(candidate.benefitsStatus.insuranceTermed)
            return false;
        if(candidate.comboOfBenefitsIssue.isCobIssue)
            return false;

        return true;
    }
    
}
