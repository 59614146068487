import { Procedure } from '../../../../services/orgService/model';
import { DrgCombo } from '../../../../services/verifyService/model/verification/DrgCombo';
import { cptCombo } from './DrgGroups';

export class DrgMatch {
    /**
     * Determines the best matching DRG combination based on the provided procedures.
     *
     * @param procedures - An array of Procedure objects to be matched against the CPT combinations.
     * @returns The best matching DrgCombo object. If no match is found, returns a DrgCombo with "0" and an empty array.
     */
    findBestMatch(procedures: Procedure[], currentDrgCode: string): DrgCombo {
        let bestMatch: DrgCombo = new DrgCombo();
        bestMatch.PreviousDrg = currentDrgCode;
        let maxMatches = 0;

        for (const combo of cptCombo) {
            const matchedCPTs = combo.CPT.filter(cpt => procedures.some(proc => proc.code === cpt));
            if (matchedCPTs.length > maxMatches && matchedCPTs.length === combo.CPT.length) {
                maxMatches = matchedCPTs.length;
                bestMatch = new DrgCombo();
                bestMatch.DRG = combo.DRG;
                bestMatch.CPT = matchedCPTs;
                bestMatch.PreviousDrg = currentDrgCode === "0" ? combo.DRG : currentDrgCode;
            }
        }
        return bestMatch;
    }
}